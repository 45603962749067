<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  database: true,
  computed: {
    page() {
      const pageStore = usePageStore()
      return pageStore.currentPage
    },
    buttonStyles() {
      if (this.page.site_id === 2)
        return 'mb-4 text-light bg-sanctuary-primary text-sanctuary-secondary px-6 py-4 text-xs uppercase'
      else
        return 'btn my-4 book-now'
    },
  },

  mounted() {
    defineSlice({ name: { label: 'Special Offer Horizontal', group: 'playaresorts' }, fields: { image: { type: 'media', label: 'Background Image', breakpoints: { default: { width: 1200 } }, name: 'image' }, html_id: { type: 'text', label: 'HTML ID', name: 'html_id' }, title: { type: 'text', label: 'Title', name: 'title' }, subtitle: { type: 'text', label: 'Subtitle', name: 'subtitle' }, content: { type: 'text', label: 'Content', name: 'content' }, percentOff: { type: 'text', label: 'Percent Off', name: 'percentOff' }, extraSavings: { type: 'text', label: 'Extra Savings', name: 'extraSavings' }, slug: { type: 'text', label: 'Slug', name: 'slug' }, terms: { type: 'text', label: 'Terms', name: 'terms' }, prettyEndDate: { type: 'text', label: 'End Date', name: 'prettyEndDate' }, hideBookNow: { type: 'checkbox', label: 'Hide Book Now', name: 'hideBookNow' } }, slots: [] })
  },
}
</script>

<template>
  <div class="w-full mb-6 flex justify-stretch items-stretch">
    <div class="flex items-stretch flex-col md:flex-row min-w-full special-offer-card-horizontal p-6">
      <VoixMedia
        v-slot="slotProps"
        :field="fields?.image"
        :background="true"
      >
        <div
          :style="{ backgroundImage: `url(${slotProps.image})` }"
          class="inset-outline w-full md:w-3/5 bg-cover bg-center aspect-[4/3]"
        />
      </VoixMedia>

      <div :id="fields?.html_id?.value" class="md:px-8 pb-8 md:w-1/2">
        <div class="my-8 md:2-1/2">
          <span class="block uppercase mb-1 text-sm">{{ fields?.title?.value }}</span>
          <h3 class="text-3xl mb-4" v-html="fields?.subtitle?.value" />
          <div class="special-html-override" v-html="fields?.content?.value" />
        </div>
        <div v-if="fields?.percentOff?.value" class="flex flex-col items-center mb-8">
          <div class="flex items-center uppercase mb-2">
            <div class="text-5xl font-bold">
              Save
            </div>
            <div class="flex flex-col text-xs mx-1">
              <div>Up</div>
              <div>To</div>
            </div>
            <div class="text-5xl font-bold">
              {{ fields?.percentOff?.value }}
            </div>
          </div>
          <div v-if="fields?.extraSavings?.value" class="font-bold" v-html="fields?.extraSavings?.value" />
        </div>
        <PlayaBooking
          v-if="
            page.path !== 'agent-offers'
              && page.path !== 'es/agent-offers'
              && (!fields?.hideBookNow?.value || fields?.hideBookNow?.value === '0')
          "
        >
          <button :class="buttonStyles">
            {{ $t('book-now') }}
          </button>
        </PlayaBooking>

        <terms :terms="fields?.terms?.value">
          <button class="btn btn-text mb-2">
            {{ $t('terms-and-conditions') }}
          </button>
        </terms>
        <div v-if="fields?.prettyEndDate?.value">
          {{ $t('offer-expires') }}: {{ fields?.prettyEndDate?.value }}
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.special-html-override {
  h1, h2, h3, h4, h5, h6 {
    @apply font-sans font-normal text-base #{!important};
  }
}

.hilton-theme {
  .special-offer-card-horizontal {
    @apply border-none p-0;
  }
}
</style>
